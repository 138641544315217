(function(){
    let myAction = {};
    let gstore = {};
    let g = {};

    myAction.create = (token, url, data)=>{
        if (!data.isArray()) {
            return false;
        }
        $.ajax({
            url: url,
            method: 'POST',
            async: false,
            data:{
                '_token':token,
            },
            success: (d)=>{
                return true;
            }
        }).fail((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.statusText,
            });
            return false;
        });
    }

    myAction.delete = (display_name, token, url)=>{
        Swal.fire({
            title: 'Are you sure you want to delete `'+display_name+'`?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                $.ajax({
                    url: url,
                    method: 'POST',
                    async: false,
                    data: {
                        '_token':token,
                        '_method':'DELETE'
                    },
                    success: (d)=>{
                        d = JSON.parse(d);
                        if (d.status === true) {
                            location.reload();
                        }
                    }
                }).fail((err)=>{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.statusText,
                    });
                });
            }
        });
        return true;
    }

    /**
     * Ticketing Notification
     * @param {*} summary_count int
     * @param {*} detail array [{'name':'xyz', 'url':'/action', 'count':0},]
     * @returns
     */
    myAction.setNotification = (summary_count, detail, notification_tag, icon)=>{
        let notification_alert = $('#' + notification_tag);
        if (notification_alert.length) {
            if (summary_count==0) {
                notification_alert.html('');
            } else {
                let alert_content = '<a class="nav-link text-white" data-toggle="dropdown" href="#">';
                alert_content += '<i class="' + icon + '"></i>';
                alert_content += '<span class="badge badge-danger navbar-badge">'+summary_count+'</span>';
                alert_content += '</a>';
                alert_content += '<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">';
                if (summary_count>1) {
                    alert_content += '<span class="dropdown-item dropdown-header">'+summary_count+' Notifications</span>';
                } else {
                    alert_content += '<span class="dropdown-item dropdown-header">'+summary_count+' Notification</span>';
                }
                if (detail.length) {
                    for (let i = 0; i < detail.length; i++) {
                        alert_content += '<div class="dropdown-divider"></div>';
                        alert_content += '<a class="dropdown-item" href="'+detail[i].url+'">';
                        alert_content += '  <i class="'+detail[i].icon+' mr-2"></i>';
                        alert_content += '  <span title="'+detail[i].name+': '+detail[i].count+'">'+detail[i].name+': '+detail[i].count+'</span>';
                        alert_content += '</a>';
                    }
                }
                alert_content += '</div>';
                notification_alert.html(alert_content);
            }
        }
        return true;
    }

    gstore.lastSumNotif = 0;
    gstore.lastSumMailNotif = 0;
    gstore.lastSumInspecNotif = 0;
    gstore.lastSumBusinessTripNotif = 0;

    g.createInspection = function(url_customer_schedule, url_create, customer, schedule){
        let token = $("meta[name=csrf-token]").attr("content");
        let datas = {'customer':customer, 'schedule':schedule, '_token':token}
        $.ajax({
            url:url_customer_schedule,
            method:'post',
            data:datas,
            success: (d)=>{
                if (d.status) {
                    window.location = url_create;
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Attention!',
                        text: d.message,
                    });
                }
            }
        }).fail((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.statusText,
            });
        });
    }

    g.inspectionStatus = function(id,type){
        let input_other = $('input[name="status_other['+id+']"]');
        let input_description = $('textarea[name="description['+id+']"]');
        let input_action = $('textarea[name="action['+id+']"]');
        let input_suggestion = $('textarea[name="suggestion['+id+']"]');

        if (type == 'Normal') {
            input_other.prop("readonly", false).prop('required', false).attr('class', 'form-control form-control-sm d-none');
            if (input_other.val()!='') {input_other.val('');}
            input_description.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_description.val()!='') {input_description.attr('class', 'form-control form-control-sm is-valid');}
            input_action.prop("readonly", true).prop('required', false).attr('class', 'form-control form-control-sm');
            if (input_action.val()!='') {input_action.val('');}
            input_suggestion.prop("readonly", true).prop('required', false).attr('class', 'form-control form-control-sm');
            if (input_suggestion.val()!='') {input_suggestion.val('');}
            $("#input-description-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            $("#input-action-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            $("#input-suggestion-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            input_description.focus();
        } else if(type == 'Abnormal') {
            input_other.prop("readonly", false).prop('required', false).attr('class', 'form-control form-control-sm d-none');
            if (input_other.val()!='') {input_other.val('');}
            input_description.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_description.val()!='') {input_description.attr('class', 'form-control form-control-sm is-valid');}
            input_action.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_action.val()!='') {input_action.attr('class', 'form-control form-control-sm is-valid');}
            input_suggestion.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_suggestion.val()!='') {input_suggestion.attr('class', 'form-control form-control-sm is-valid');}
            $("#input-description-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            $("#input-action-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            $("#input-suggestion-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            input_description.focus();
        } else if(type == 'Other') {
            input_other.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_other.val()!='') {input_other.attr('class', 'form-control form-control-sm is-valid');}
            input_description.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_description.val()!='') {input_description.attr('class', 'form-control form-control-sm is-valid');}
            input_action.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_action.val()!='') {input_action.attr('class', 'form-control form-control-sm is-valid');}
            input_suggestion.prop("readonly", false).prop('required', true).attr('class', 'form-control form-control-sm is-invalid');
            if (input_suggestion.val()!='') {input_suggestion.attr('class', 'form-control form-control-sm is-valid');}
            $("#input-description-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            $("#input-action-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            $("#input-suggestion-"+id).attr("class", "col-md-4 border pt-2 pb-2");
            input_description.focus();
        }
    }

    g.inspectionTextChange = function(key){
        if (key.length) {
            if (key.val()!='') {
                key.attr('class', 'form-control form-control-sm is-valid');
            } else {
                key.attr('class', 'form-control form-control-sm is-invalid');
            }
        }
    }

    g.inspectionCheckStepperInput = function(category_id, stepper_content_name, row_num){
        let tag_stepper = '#'+stepper_content_name+' input, #'+stepper_content_name+' textarea';
        let currentForm = $(tag_stepper);
        let button_tag = $('#btn-next-' + stepper_content_name);
        // cek radio
        for (let i = 0; i < currentForm.length; i++) {
            let currentTag = currentForm[i];
            if (currentTag.type == 'radio') {
                let tagChecked = $('[name="'+currentTag.name+'"]:checked').length;
                row_num++;
                if (!tagChecked) {
                    // Plese select all radio status
                    button_tag.prop('disabled', true);  // button next disable
                    return false;
                }
            }
        }
        // cek input
        for (let i = 0; i < currentForm.length; i++) {
            let currentTag = currentForm[i];
            if (currentTag.type == 'text') {    // if current tag is text
                let tagRequired = $('[name="'+currentTag.name+'"]');  // jquery selector
                if (tagRequired[0].required) {  // if tag required
                    if (tagRequired.val() == '') { // if text empty, return false
                        // Please fill other text
                        tagRequired.focus();
                        button_tag.prop('disabled', true);  // button next disable
                        return false;
                    }
                }
            }
        }
        // cek textarea
        for (let i = 0; i < currentForm.length; i++) {
            let currentTag = currentForm[i];
            if (currentTag.type == 'textarea') {    // if current tag is textarea
                let tagRequired = $('[name="'+currentTag.name+'"]');  // jquery selector
                if (tagRequired[0].required) {  // if tag required
                    if (tagRequired.val() == '') {  // if textarea empty, return false
                        // Please fill red textarea
                        tagRequired.focus();
                        button_tag.prop('disabled', true);  // button next disable
                        return false;
                    }
                }
            }
        }
        // button next (enable or disable)
        button_tag.prop('disabled', false);  // button next enable
        return true;
    }

    /**
     *
     * @param {*} url_stepper_step url post data
     * @param {*} trigger NEXT or SAVE
     */
    g.inspectionStepperStep = function(trigger, stepper_content_name){
        let url_stepper_step = $('input[name=stepper_step]').val();
        let token = $("meta[name=csrf-token]").attr("content");
        let inputStatus = $('#'+stepper_content_name+' input[type=radio]:checked');
        let inputStatusOther = $('#'+stepper_content_name+' input[type=text]');
        let statusList = [];
        for (let i = 0; i < inputStatus.length; i++) {
            let tmps = ((inputStatus[i].name).replace(']', '')).split('[');
            let tmp = {'name':inputStatus[i].name, 'key':tmps[1], 'value':inputStatus[i].value};
            if (inputStatusOther[i].value != '') {
                tmp.value += "|" + inputStatusOther[i].value;
            }
            statusList.push(tmp);
        }
        let textareaList =  $('#'+stepper_content_name+' textarea');
        let descriptionList = [];
        let actionList = [];
        let suggestionList = [];
        for (let i = 0; i < textareaList.length; i++) {
            let tmp = ((textareaList[i].name).replace(']','')).split('[');
            if (tmp[0] == 'description') {
                descriptionList.push({'name':textareaList[i].name, 'key':tmp[1], 'value':textareaList[i].value});
            } else if(tmp[0] == 'action'){
                actionList.push({'name':textareaList[i].name, 'key':tmp[1], 'value':textareaList[i].value});
            } else if(tmp[0] == 'suggestion'){
                suggestionList.push({'name':textareaList[i].name, 'key':tmp[1], 'value':textareaList[i].value});
            }
        }
        let datas = {
            '_token'            : token,
            'customer_id'       : $('input[name=customer_id]').val(),
            'inspector_id'      : $('input[name=inspector_id]').val(),
            'schedule_id'       : $('input[name=schedule_id]').val(),
            'inspection_date'   : $('input[name=inspection_date]').val(),
            'trigger'           : trigger,
            'fstatus'           : statusList,
            'fdescription'      : descriptionList,
            'faction'           : actionList,
            'fsuggestion'       : suggestionList,
        }
        $.ajax({
            url:url_stepper_step,
            method:'post',
            data:datas,
            success: (d)=>{
                if (d.status) {
                    if (trigger == 'NEXT') {
                        stepper1.next();
                    } else {
                        window.location = d.redirect;
                    }
                }
            }
        }).fail((err)=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.statusText,
            });
        });
    }

    g.numberFormat = function(tag){
        let value = tag.val();
        console.log(value);
    }
    g.sideMenuCollapse = ()=> {
        let body = $('body');
        let newClass = (body.attr('class') + ' sidebar-collapse').replace('sidebar-mini', ' ');
        body.attr('class', newClass);
    }

    g.setLocalNumber = function(currentTag){
        if(currentTag.val().substr(-1) != '.' && currentTag.val() != ''){
            currentTag.val( LocalNumberFormat(currentTag.val()) );
        }
    }

    this.myAction = myAction;
    this.gstore = gstore;
    this.g = g;
})(this);


$(function() {
    // select2
    setTimeout(() => {
        $('select[aria-controls=select2]').select2();
    }, 500);

    // Datepicker
    $('input[type=date]')
    .prop('type','text')
    .daterangepicker({
        "locale":{
            "format": "YYYY-MM-DD",
        },
        "singleDatePicker": true,
        "showDropdowns": true,
        "showWeekNumbers": false,
        "autoApply": true,
        "alwaysShowCalendars": true,
        "drops": "auto"
    });

    // Input Number
    let inputNumbers = $('input[type=text][role=input-number]');
    for (let i = 0; i < inputNumbers.length; i++) {
        let currentTag = $('#' + inputNumbers[i].id);
        currentTag.on('keyup', ()=>{
            if(currentTag.val().substr(-1) != '.' && currentTag.val() != ''){
                currentTag.val( LocalNumberFormat(currentTag.val()) );
            }
        });
        if (currentTag.val() != '') {
            currentTag.val( LocalNumberFormat(currentTag.val()) );
        }
    }
    // Input Number Form Submit
    let forms = $('form[role=form]');
    forms.submit(()=>{
        let inputNumbers = $('form[role=form] input[role=input-number]');
        for (let i = 0; i < inputNumbers.length; i++) {
            let cTag = $('#' + inputNumbers[i].id);
            cTag.val( LocalNumberUnformat(cTag.val()) );
        }
    });

});

/**
 * Reinitialize select2 when resize window
 */
window.addEventListener('resize', ()=>{
    $('select[aria-controls=select2]').select2();
});

window.LocalNumberFormat = function(inputNumber){
    let currentVal = parseFloat( inputNumber.replace(/,/g, '') );
    return isNaN(currentVal) ? 0 : currentVal.toLocaleString('en-US');
}
window.LocalNumberUnformat = function(inputNumber){
    return inputNumber.replace(/,/g, '');
}

window.LocalNumberUnFormatForm = function(){
    let inputNumbers = $('form[role=form] input[role=input-number]');
    for (let i = 0; i < inputNumbers.length; i++) {
        let cTag = $('#' + inputNumbers[i].id);
        cTag.val( LocalNumberUnformat(cTag.val()) );
    }
}

window.GambarRatio = function(tag, color, percent){
    this.tag = tag;
    this.color = {back: '#ababab', front: color};
    this.percent = percent;
    this.radius = (tag.width/2)-25;
    this.point = {x: tag.width/2,y: tag.height/2};

    this.c = tag.getContext('2d');

    this.draw = function(){
        this.c.clearRect(0,0, tag.width, tag.height);
        this.c.lineWidth = 10;

        this.c.beginPath();
        this.c.textAlign = 'center';
        this.c.font = "14px Arial";
        this.c.fillText(this.percent + "%", this.point.x, (this.point.y+8));
        this.c.closePath();

        this.c.beginPath();
        this.c.strokeStyle = this.color.back;
        this.c.arc(this.point.x, this.point.y, this.radius, 0, ((Math.PI*2)*100)/100, false);
        this.c.stroke();
        this.c.closePath();

        this.c.beginPath();
        this.c.strokeStyle = this.color.front;
        this.c.arc(this.point.x, this.point.y, this.radius, 0, ((Math.PI*2)*this.percent)/100, false);
        this.c.stroke();
        this.c.closePath();
    }

    this.update = function(color, percent){
        this.color.front = color;
        this.percent = percent.toFixed(2);

        this.draw();
    }
}
